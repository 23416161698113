import { AuthAdapterService } from "../../../services/auth.service";
import { User } from "../../../entities/user";
import { Injectable } from "@angular/core";
import { environment } from "../../../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { Cookie } from "../../../../../services/cookie.service";

@Injectable()
export class DuoAuthAdapterService extends AuthAdapterService {

  private user: any;

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private route: ActivatedRoute,
    private readonly cookie: Cookie) {
    super();
  }

  public get currentUser(): User {
    return this.user;
  }

  public get isAuthenticated(): boolean {
    return this.user != null;
  }

  public init() {
    this.login();
  }

  public login() {
    if (!this.user) {

      let redirectURL = this.route.snapshot.queryParams['redirectUrl'];
      if (redirectURL != null) {
        this.cookie.setCookie("auth.redirect", redirectURL, 1 / 24 / 60);
      }

      this.http.get(environment.api + 'user')
        .subscribe(
          (x) => this.user = x);
    } else {
      let redirectURL = this.cookie.getCookie("auth.redirect");
      if (redirectURL != null) {
        window.setTimeout(() =>
          this.router.navigate([redirectURL]), 1000);
      }
      this.cookie.deleteCookie("auth.redirect");
    }
  }

  public logout() {
    if (this.user) {
      this.http.post(environment.api + 'user/logout', null)
        .subscribe(x => this.user = null);
    }
  }

  public onWindowInit() { }
}
