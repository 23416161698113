import { Component, AfterViewInit, ViewContainerRef, ViewChild } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { Menu } from "./modules/shared/services/menu.service";
import { AppTopBarState, SearchItem } from './app.topbar.state';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements AfterViewInit {

  megaMenuClick: boolean;
  searchResults: any;
  searchValue: SearchItem;

  @ViewChild("userHost", { read: ViewContainerRef, static: false }) userHost: ViewContainerRef;
  @ViewChild("mobileUserHost", { read: ViewContainerRef, static: false }) mobileUserHost: ViewContainerRef;

  constructor(public app: AppMainComponent, private readonly menu: Menu, private readonly state: AppTopBarState) {
    this.state.searchResults.subscribe((res) => this.searchResults = res);
  }

  ngAfterViewInit(): void {
    this.menu.getComponents().subscribe((x) => {
      //setTimeout(() => {
      for (var c of x) {
        this.userHost.createComponent(c).instance;
        this.mobileUserHost.createComponent(c).instance;
      }
      //}, 1);            
    });
  }

  onMegaMenuButtonClick(event) {
    this.state.changeMegaMenuClick(true);
    this.state.changeMegaMenuActive(!this.state.getMegaMenuActive());
    event.preventDefault();
  }

  onMegaMenuMobileButtonClick(event) {
    this.state.changeMegaMenuClick(true);
    this.state.changeMegaMenuMobileActive(!this.state.getMegaMenuMobileActive());
    event.preventDefault();
  }

  onSearch(event: any) {
    this.state.setSearchTerm(event.query);
  }

  onSearchValueChange() {
    this.state.setSearchValue(this.searchValue);
  }
}
