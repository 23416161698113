import { Component, AfterViewInit } from '@angular/core';
import { AuthAdapterService } from '../services/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.less']
})
export class LoginComponent implements AfterViewInit {

    public get isAuthenticated() {
        return this.service.isAuthenticated;
    }

    constructor(private service: AuthAdapterService) {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.service.login();
        }, 1);
    }
}
