import { Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Notification {

  private readonly hubConnection: HubConnection;
  private topics = {};

  constructor() {
    this.hubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Debug)
      .withUrl(environment.api + 'notifications', {
        withCredentials: false,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  public publish(topic: string, ...args: any) {
    for (var i in this.topics[topic]) {
      this.topics[topic][i](...args);
    }
  }

  public subscribe(topic: string, newMethod: (...args: any) => void) {
    if (!this.topics[topic])
      this.topics[topic] = [];
    this.topics[topic].push(newMethod);
    this.hubConnection.on(topic, newMethod);
  }

  public unsubscribe(topic: string, newMethod: (...args: any) => void) {
    
    var index = this.topics[topic].indexOf(newMethod);
    if (index >= 0)
      this.topics[topic].splice(index, 1);
    
    this.hubConnection.off(topic, newMethod);
  }
}
