import { User } from "../entities/user";
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private readonly adapterService: AuthAdapterService) {
    }

    public get currentUser(): User {
        return this.adapterService.currentUser;
  }

  public login(redirectUrl?: string) {
    return this.adapterService.login(redirectUrl);
  }
}

export abstract class AuthAdapterService {
    public abstract get isAuthenticated(): boolean;
    public abstract get currentUser(): User;
    public abstract init();
  public abstract login(redirectUrl?:string);
    public abstract logout();
    public abstract onWindowInit();
}

export abstract class AuthInterceptor2 implements HttpInterceptor {
  abstract intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>;
}

