import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'eventStatus' })
export class EventStatusPipe implements PipeTransform {
  transform(value: number) {
    if (value == 0)
      return "Pending";
    else if (value == 1)
      return "Success";
    else if (value == 2)
      return "Failed";
    return value;
  }
}
