import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment';
import { Event } from '../entities/event'
import { map } from 'rxjs/operators';

@Injectable()
export class EventService {

  constructor(private readonly http: HttpClient) {
  }

  public getEvents(completed?: boolean, externalId?: string, includeTypes?: Array<string>, excludeTypes?: Array<string>, limit?: number): Observable<Array<Event>> {
    let params = new HttpParams();
    if (completed != undefined)
      params = params.set("completed", String(completed));
    if (externalId)
      params = params.set("externalId", externalId);
    if (limit)
      params = params.set("limit", String(limit));
    if (includeTypes)
      for (var i of includeTypes)
        params = params.set("includeTypes", i);
    if (excludeTypes)
      for (var i of excludeTypes)
        params = params.set("excludeTypes", i);
    return this.http
      .get<Array<Event>>(environment.api + 'events', { params: params })
      .pipe(map(x => {
        for (var i in x) {
          x[i].processedDate = new Date(x[i].processedDate + 'Z');
        }
        return x;
      }));;
  }
}
