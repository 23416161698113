import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatter } from '../date-formatter';
import moment from "moment";

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {
  transform<T>(collection: Array<T>, property: string): Array<{ key: any, value: Array<T> }> {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return null;
    }

    const groupedCollection = collection.reduce((previous, current) => {
      if (!previous[current[property]]) {
        previous[current[property]] = [current];
      } else {
        previous[current[property]].push(current);
      }

      return previous;
    }, {});

    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
  }
}

@Pipe({ name: 'groupByDays' })
export class GroupByDaysPipe implements PipeTransform {
  transform<T>(collection: Array<T>, property: string, days: number): Array<{ key: any, value: Array<T> }> {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return null;
    }
    
    const groupedCollection = collection.reduce((previous, current) => {
      var x = 0;
      var date = DateFormatter.toDate(current[property]);
      if (date == undefined)
        date = <any>new Date(current[property]);
      var now = new Date();
      if (date != undefined) {        
            var diff = Math.round((<any>date - <any>now) / (86400000));//1000 * 60 * 60 * 24
        if (diff >= 0)
          x = Math.round(diff / days + 1) * days;
      }
      now.setDate(now.getDate() + x);
      var key = moment(now).format('MM/DD/YYYY');
      if (!previous[key]) {
        previous[key] = [current];
      } else {
        previous[key].push(current);
      }

      return previous;
    }, {});

      // this will return an array of objects, each object containing a group of objects
      //var res = new Array<{ key: any, value: Array<T> }>();
      //res.push({ key:'01/01/2019', value: collection });
      return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
  }
}
