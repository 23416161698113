import { Component } from '@angular/core';
import { ToolBar } from './modules/shared/services/toolbar.service';

@Component({
  selector: 'my-about',
  templateUrl: 'about.component.html'  
})
export class AboutComponent {

  constructor(private toolbar: ToolBar) {
    this.toolbar.setTitle('About');
  }
}
