import { AuthAdapterService } from "../../../services/auth.service";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { User } from "../../../entities/user";
import { Inject, Injectable } from "@angular/core";
import { Cookie } from "../../../../../services/cookie.service";
import { Router, ActivatedRoute } from "@angular/router";
import { InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import { filter, takeUntil } from "rxjs/operators";

@Injectable()
export class AzureAdAdapterService extends AuthAdapterService {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private readonly service: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private readonly cookie: Cookie) {
    super();
  }

  private user: User = null;

  public get currentUser(): User {

    if (!this.isAuthenticated) {
      return null;
    }

    if (this.user == null) {
      this.user = new User();

      this.user.userName = this.service.instance.getActiveAccount().username;
      this.user.profileName = this.service.instance.getActiveAccount().name;

      if (this.user.avatar == null) {
        this.user.avatar = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwwAADsMBx2+oZAAAABh0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMS41ZEdYUgAAAWxJREFUeF7t2ElywjAQBVDuf+mkEl6Vi8G0AVuW1P12yFIPf8mlpZ+dKDcHOx1Gm7GYvTnt+2XO0xmnH+bqjvHOZZZuGbM9/Ydh7Db0HI7xj6XXsKxxDD2GZ519qT0Na+1DzelY7ztqTcuan1FjetZ9j7dpWHs779Kw9jbepGP919xNSwzr3EtLDM+5k544Hvmenjhu+VYQy8J5QSwL5wWxXDkrd8RTAa0RTwW0psLZoAIKVECBCihQAQUqoEAFFKiAAhVQoAIKVECBCihQAQUqoEAFFKiAAhVQoAIK1L+KL/yH88fvckc8FdAa8Vw5K4hl4bwgloXzglhu+ZaeOB75np44nnMnLTGscy8tMbzmbjrW38abNKy9nXdpWPs93k7Pup9RY1rW/I5a07HePtSchrX2pfbwrHMMPYZljWPpNRzjt6HnMIzdnv7dMua5zNId4/XDXKczTr/M2Zz2YzH7YbSZg52+plwDl8svwc1CULn6lscAAAAASUVORK5CYII=';
        //this.service.acquireToken('https://graph.microsoft.com')
        //    .subscribe(token => alert(token));
        //var token = this.service.getCachedToken('https://graph.microsoft.com');
        //this.http.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
        //    headers: {
        //        "Authorization": 'Bearer ' + token
        //    }
        //}).subscribe(
        //    (x) => this.user.avatar = 'data:image/*;base64,' + (<any>x).data); // 'assets/layout/images/topbar/avatar-eklund.png';
        //this.service.acquireToken('https://graph.microsoft.com')
        //    .subscribe(
        //        token => this.http.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
        //            headers: {
        //                "Authorization": 'Bearer ' + token
        //            }
        //        }).subscribe(
        //            (x) => this.user.avatar = 'data:image/*;base64,' + (<any>x).data) // 'assets/layout/images/topbar/avatar-eklund.png';
        //    );                   
      }
    }

    return this.user;
  }

  public get isAuthenticated(): boolean {
    return this.service.instance.getActiveAccount() != null;
  }

  public init() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        //takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      })
  }

  public login(redirectUrl) {
    if (!this.isAuthenticated) {
      //let redirectURL = this.route.snapshot.queryParams['redirectUrl'];
      if (redirectUrl != null) {
        this.cookie.setCookie("auth.redirect", redirectUrl, 5 / 24 / 60, "/");
      }
      if (this.msalGuardConfig.authRequest) {
        var authRequest = { ...this.msalGuardConfig.authRequest } as RedirectRequest;
        this.service.loginRedirect(authRequest);
      } else {
        this.service.loginRedirect();
      }
    }
  }

  public logout() {
    this.service.logoutRedirect();
  }

  public onWindowInit() {
    //this.service.handleWindowCallback();
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.service.instance.getActiveAccount();

    if (!activeAccount && this.service.instance.getAllAccounts().length > 0) {
      let accounts = this.service.instance.getAllAccounts();
      this.service.instance.setActiveAccount(accounts[0]);

      let redirectURL = this.cookie.getCookie("auth.redirect");
      if (redirectURL) {
        this.router.navigateByUrl(redirectURL).catch(() => this.router.navigate(['']));
        this.cookie.deleteCookie("auth.redirect");
      }      
    }
  }
}


//@Injectable({
//    providedIn: 'root',
//})
//export class AzureAdInterceptorService extends AuthInterceptorService {
//    constructor(interceptor: AdalInterceptor2) {
//        super();
//        this.setInterceptor(interceptor);
//    }
//}
