import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'labeled-component',
  templateUrl: 'labeled-component.component.html',
  styleUrls: ['labeled-component.component.less']
})
export class LabeledComponent {
  @Input() label: string;  
}
