import { NgModule, Injector } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserComponent } from "./components/user.component";
import { LogoutComponent } from "./components/logout.component";
import { LoginComponent } from "./components/login.component";
import { AuthRoutingModule } from "./auth.routing";
import { RouteGuardService } from "../shared/services/route-guard.service";
import { environment } from "../../../environments/environment";

import { providers as AzureAD } from "./providers/azure-ad/azure-ad.provider";
import { providers as Duo } from "./providers/duo/duo.provider";
import { AuthInterceptorService } from "../shared/services/auth-interceptor.service";
import { AuthService, AuthInterceptor2, AuthAdapterService } from "./services/auth.service";
import { AuthGuardStrategy } from "./services/auth-guard.service";
import { MsalModule } from "@azure/msal-angular";

let configuration = environment.auth;
if (configuration == null)
  throw new Error('"auth" module configuration was missed.');

let providers = null;
let modules = null;
switch (configuration.provider) {
  case 'none':
    providers = [];
    modules = [];
    break;
  case 'azure-ad':
    providers = AzureAD;
    modules = [MsalModule];
    break;
  case 'duo':
    providers = Duo;
    modules = [];
    break;
  default:
    throw new Error(`"${configuration.provider}" is unknown provider in auth module.`);
}

@NgModule({
    imports: modules.concat([
        CommonModule,
        AuthRoutingModule
    ]),
    exports: [UserComponent],
    declarations: [
        LogoutComponent,
        LoginComponent,
        UserComponent
    ],
    providers: providers.concat([
        AuthService,
        AuthGuardStrategy
    ])
})
export class AuthModule {
  constructor(
    injector: Injector,
    guardService: RouteGuardService,
    authService: AuthInterceptorService,
    adapterService: AuthAdapterService) {
    guardService.addGuard(injector.get(AuthGuardStrategy));
    authService.setInterceptor(injector.get(AuthInterceptor2));
    adapterService.init();
  }
}
