import { NgModule, ComponentFactoryResolver } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./components/login.component";
import { LogoutComponent } from "./components/logout.component";
import { Menu } from "../shared/services/menu.service";
import { UserComponent } from "./components/user.component";

var authRoutes: Routes = [
    { path: 'logout', component: LogoutComponent, data: { title: 'VMS Web' } },
    { path: 'login', component: LoginComponent, data: { title: 'VMS Web' } }
];

@NgModule({
    imports: [RouterModule.forChild(authRoutes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private menu: Menu) {
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserComponent);
        menu.addComponent(componentFactory);
    }
}
