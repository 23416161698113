import { Observable, interval } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { Pipe, ChangeDetectorRef } from '@angular/core';


@Pipe({
    name: 'timeAgo',
    pure: false
})
export class TimeAgoPipe extends AsyncPipe
{
    value:Date;
    timer:Observable<string>;

    constructor(ref:ChangeDetectorRef)
    {
        super(ref);
    }

    transform(obj:any/*, args?:any[]*/):any
    {
        if (obj instanceof Date)
        {
            this.value = obj;

            if(!this.timer)
            {
                this.timer = this.getObservable();
            }

            return super.transform(this.timer/*, args*/);
        }

        return super.transform(obj/*, args*/);
    }

    private getObservable()
    {
      return interval(1000)
        .pipe(startWith(0))
        .pipe(map(() =>
        {
          var result: string;

            // current time
            let now = new Date().getTime();

            // time since message was sent in seconds
            let delta = (now - this.value.getTime()) / 1000;
            
            // format string
            if (delta < 10)
            {
                result = 'just now';
            }
            else if (delta < 60)
            {
                result = `${ Math.floor(delta) } seconds ago`;
            }
            else if (delta < 3600)
            { 
                result = `${ Math.floor(delta / 60) } minutes ago`;
            }
            else if (delta < 86400)
            { 
                result = `${ Math.floor(delta / 3600) } hours ago`;
            }
            else
            { 
                result = `${ Math.floor(delta / 86400) } days ago`;
            }
            return result;
        }));
    };
}
