import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatter } from '../date-formatter';

@Pipe({ name: 'toRemainingDays' })
export class RemainingDaysPipe implements PipeTransform {
  transform<T>(value: string, depth?: number, prompt?: boolean): string {
    if (depth == null)
      depth = Number.POSITIVE_INFINITY;
    return DateFormatter.toRemainingDaysString(value, depth, prompt);
  }
}
