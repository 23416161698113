import { Component, Renderer2, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MenuService } from './app.menu.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppTopBarState } from './app.topbar.state';
import { environment } from '../environments/environment';
import { EventService } from './modules/shared/services/event.service';
import { Event } from './modules/shared/entities/event';
import { Notification } from "./modules/notification/services/notification.service";
import { WorkItem } from './modules/shared/work-item';
import { EventClickEvent } from './app.events';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-main',
  templateUrl: './app.main.component.html',
  styles: [`
		:host ::ng-deep .layout-rightpanel-messages {
			z-index: 1000;
		}		
	`],
  animations: [
    trigger('mask-anim', [
      state('void', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 0.8
      })),
      transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)'))
    ])
  ]
})
export class AppMainComponent implements AfterViewInit, OnInit, OnDestroy {

  applicationLogo: string = `\\assets\\icons\\${environment.branding.logo}.svg`;
  horizontalMenu: boolean;
  darkMode = false;
  menuColorMode = 'light';
  menuColor = 'layout-menu-light';
  themeColor = 'blue';
  layoutColor = 'blue';
  rightPanelClick: boolean;
  rightPanelActive: boolean;
  rightPanelHeader: string;
  menuClick: boolean;
  staticMenuActive: boolean;
  menuMobileActive: boolean;
  megaMenuMobileActive: boolean;
  topbarItemClick: boolean;
  topbarMobileMenuClick: boolean;
  topbarMobileMenuActive: boolean;
  configDialogActive: boolean;
  sidebarActive: boolean;
  activeTopbarItem: any;
  topbarMenuActive: boolean;
  menuHoverActive: boolean;
  events: Event[] = [];
  downloads: Event[] = [];
  processEvent = (event) => this.onEventProcessed(event);

  constructor(
    private readonly eventService: EventService,
    private readonly notification: Notification,
    public renderer: Renderer2,
    private menuService: MenuService,
    private messageService: MessageService,
    private state: AppTopBarState,
    private readonly workItem: WorkItem) {
  }

  ngAfterViewInit(): void {
    this.eventService.getEvents(true, null, null, ["Bomars.Ops.Operations.CreateStandardReportOperation,Bomars.Ops"], 10)
      .subscribe((y) => {
        this.events = y;
      });
    this.eventService.getEvents(null, null, ["Bomars.Ops.Operations.CreateStandardReportOperation,Bomars.Ops"], null, 10)
      .subscribe((d) => {
        this.downloads = d;
      });
  }

  ngOnInit() {
    this.state.megaMenuMobileActive.subscribe(x => this.megaMenuMobileActive = x);
    this.notification.subscribe('EventProcessed', this.processEvent);
  }

  ngOnDestroy() {
    this.notification.unsubscribe('EventProcessed', this.processEvent);
  }

  onEventProcessed(e: Event) {
    if (e.eventType == "Bomars.Ops.Operations.CreateStandardReportOperation,Bomars.Ops") {
      var index = this.downloads.findIndex(x => x.eventId == e.eventId);
      if (index >= 0)
        this.downloads[index] = e;
      else
        this.downloads.unshift(e);
      if (e.status == 1)
        this.messageService.add({ key: 'reportLink', severity: 'success', summary: 'Standard reports', detail: e.description, data: e, sticky: true });
    }
    else
      this.events.unshift(e);
  }

  onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    //if (!this.rightPanelClick) {
    //    this.rightPanelActive = false;
    //}

    if (!this.state.getMegaMenuClick()) {
      this.state.changeMegaMenuActive(false);
      this.state.changeMegaMenuMobileActive(false);
    }

    if (!this.menuClick) {
      if (this.isHorizontal()) {
        this.menuService.reset();
      }

      if (this.menuMobileActive) {
        this.menuMobileActive = false;
      }

      this.menuHoverActive = false;
    }

    this.menuClick = false;
    this.topbarItemClick = false;
    this.state.changeMegaMenuClick(false);
    this.rightPanelClick = false;
  }

  onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onRightPanelButtonClick(event, mode) {
    if (!this.events) {
      this.eventService.getEvents(true, null, null, null, 10)
        .subscribe((y) => {
          this.events = y;
        });
    }

    this.rightPanelClick = true;
    if (!this.rightPanelHeader || this.rightPanelHeader == mode)
      this.rightPanelActive = !this.rightPanelActive;
    if (this.rightPanelActive)
      this.rightPanelHeader = mode;
    else
      this.rightPanelHeader = null;

    event.preventDefault();
  }

  onRightPanelClose(event) {
    this.rightPanelActive = false;
    this.rightPanelClick = false;
    this.rightPanelHeader = null;

    event?.preventDefault();
  }

  onRightPanelClick(event) {
    this.rightPanelClick = true;

    event.preventDefault();
  }

  onTopbarMobileMenuButtonClick(event) {
    this.topbarMobileMenuClick = true;
    this.topbarMobileMenuActive = !this.topbarMobileMenuActive;

    event.preventDefault();
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.topbarMenuActive = false;

    if (this.isMobile()) {
      this.menuMobileActive = !this.menuMobileActive;
    }

    event.preventDefault();
  }

  onSidebarClick(event: any) {
    this.menuClick = true;
  }

  onToggleMenuClick(event: any) {
    this.staticMenuActive = !this.staticMenuActive;
    event.preventDefault();
  }

  onReportClick(event, data: Event) {
      this.workItem.emit(new EventClickEvent(data));    
      event.preventDefault();
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isMobile() {
    return window.innerWidth <= 991;
  }

  isHorizontal() {
    return this.horizontalMenu === true;
  }
}
