import { ValidatorFn, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from "@angular/forms";
import { Directive } from "@angular/core";
import { DateFormatter } from "../date-formatter";

@Directive({
  selector: '[date]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateValidator, multi: true }]
})
export class DateValidator implements Validator {
  validate(control: AbstractControl): { [key: string]: any } {
    if (!control.value)
      return null;
    return DateFormatter.isValid(control.value) ? null : { 'dateValid': false };
  }
}

