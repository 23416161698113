import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-logout',
  templateUrl: 'logout.component.html',
  styleUrls: [ 'logout.component.less' ]
})
export class LogoutComponent implements OnInit {

  isAuthenticated = false;

  constructor(
      private authService: MsalService
  ) {
      this.isAuthenticated = this.authService.instance.getActiveAccount != null;//.userInfo.authenticated;
  }

  ngOnInit() {
    if (this.isAuthenticated) {
        this.authService.logout();
    }
  }
}
