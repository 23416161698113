import { RouteGuardStrategy } from "../../shared/services/route-guard.service";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras } from "@angular/router";
import { User } from "../entities/user";
import { Injectable } from "@angular/core";
import { HttpInterceptor } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuardStrategy implements RouteGuardStrategy {

    constructor(
        private readonly service: AuthService,
        private readonly router: Router) {        
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(childRoute, state);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        //let moduleName = route.url[0].path;//route.parent.url[0].path;
        //let routeName = route.url[0].path;

        //let module = environment.modules.find((x) => x.key == moduleName);
        //if (module == null || module.security == null)
        //    return true;

        //let security = module.security[routeName];
        //if (security == null)
        //    return true;

        const currentUser = this.service.currentUser;
        if (currentUser) {
            // check if route is restricted by role and authorised so return true
            //if (security.indexOf(currentUser.role) !== -1)
            //    return true;
            
            //if (security.indexOf('*') !== -1)
            //    return true;

            //// role not authorised so redirect to home page
            //this.router.navigate(['/']);

            //return false;
            return true;
        }

        //let navigationExtras: NavigationExtras = {
        //    queryParams: { 'redirectUrl': state.url }
        //};

      // not logged in so redirect to login page with the return url
      this.service.login(state.url);
        //this.router.navigate(['/auth/login'], {
        //    queryParams: {
        //        redirectUrl: state.url
        //    }
        //}/*, navigationExtras*/);

        return false;        
    }

}

