import { Injectable } from '@angular/core';

@Injectable()
export class Logger {

    //#region Public methods

    public debug(s: string): void { if (console && console.log) console.log(this.format(s)); }
    public info(s: string): void { if (console && console.info) console.info(this.format(s)); }
    public error(e: any): void { if (console && console.error) console.error(this.format(e)); }
    public time(s: string): void { if (console && console.time) console.time(this.format(s)); }
    public timeEnd(s: string): void { if (console && console.timeEnd) console.timeEnd(this.format(s)); }

    //#endregion

    //#region Private methods

    private format(o: any): string {
        return o && o.stack ? o + "\n" + o.stack : o;
    }

    //endregion

}