import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { BrowserCacheLocation, EventType, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "../../../../../environments/environment";

import { AuthAdapterService, AuthInterceptor2 } from "../../services/auth.service";
import { AzureAdAdapterService } from "./services/azure-ad-adapter.service";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    let configuration = environment.auth;
        if (!('settings' in configuration))
            throw new Error("Azure AD settings wasn't found.");

    var instance = new PublicClientApplication({
        auth: <any>configuration['settings'],
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
        },
        system: {
            allowRedirectInIframe: true,
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
    return instance;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  let configuration = environment.auth;
  if (!('settings' in configuration))
    throw new Error("Azure AD settings wasn't found.");
  for (var endpoint in configuration.settings.endpoints) {
    protectedResourceMap.set(endpoint, [`api://${configuration.settings.endpoints[endpoint]}/.default`]);
  }  
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

    return {
      interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read']
        },
        loginFailedRoute: '/login-failed'
    };
}

export const providers = [    
  { provide: AuthAdapterService, useClass: AzureAdAdapterService },
    { provide: AuthInterceptor2, useClass: MsalInterceptor },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
];





