import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class Menu {

    private items = new BehaviorSubject<Array<any>>([]);
    private components = new BehaviorSubject<Array<any>>([]);

    constructor() {
        //this.addLink('Tasks/fa-tasks', '/tasks', 'Operations');
    }

    public get(): Observable<Array<any>> {
        return this.items.asObservable();
    }

    public getComponents(): Observable<Array<any>> {
        return this.components.asObservable();
    }

    public addComponent(componentClass) {
        var value = this.components.getValue();
        value.push(componentClass);
        this.components.next(value);
    }

    public addLink(name: string,parent:string, link: string,menuLink:string, category?: string, group?: string, linkParams?:any[]): void {
        var value = this.items.getValue();

        if (category == null)
            category = '';

        var categoryObject = value.find((x) => x.name == category);
        if (categoryObject == null) {
            categoryObject = { name: category, items: [] };
            value.push(categoryObject);
        }

        var targetObject = categoryObject.items;

        if (group != null) {
            let groupName = group.substr(0, group.indexOf('/'));
            let groupIcon = group.substr(group.indexOf('/') + 1);
            let groupObject = categoryObject.items.find((x) => x.name == groupName);
            if (groupObject == null) {
                groupObject = { name: groupName, type: 'group', icon: groupIcon, items: [] };
                categoryObject.items.push(groupObject);
            }

            targetObject = groupObject.items;
        }

        let linkName = name;
        let linkIcon = undefined;
        if (name.indexOf('/') >= 0) {
            linkName = name.substr(0, name.indexOf('/'));
            linkIcon = name.substr(name.indexOf('/') + 1);
        }

        targetObject.push({ type: 'link', name: linkName,parent:parent, link: link,menuLink:menuLink, linkParams: linkParams, icon: linkIcon });

        this.items.next(value);
    }
}
