import moment from "moment";

export class DateFormatter {
    public static toString(date: Date): string {
        if (date == null)
            return '';
        return date.toLocaleDateString(navigator.language);
    }

    public static toDate(value: string): Date {
        if (!this.isValid(value))
            return undefined;
        let format = moment.localeData(navigator.language).longDateFormat('L');                    
        return moment(value, format).toDate();
    }

    public static isValid(value: string): boolean {
        let format = moment.localeData(navigator.language).longDateFormat('L');
        return moment(value, format).isValid();
    }

    public static toRemainingDaysString(value: string, depth: number, prompt: boolean): string {
        var date = DateFormatter.toDate(value);
        if (date == undefined)
            date = <any>new Date(value);

        if (date == undefined)
            return null;

        var now = new Date();

        var diff = Math.round((<any>date - <any>now) / (1000 * 60 * 60 * 24));

        if (prompt && diff <= 0)
            return 'Prompt';

        if (diff == 0)
            return 'Today';

        if (diff > depth)
            return DateFormatter.toString(date);

        if (diff > 0)
            return `${diff} day${diff > 1 ? 's' : ''} to go`;
        else
            return `${Math.abs(diff)} day${diff > 1 ? 's' : ''} ago`;
    }
}
