import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AboutComponent } from './about.component';
import { ContactComponent } from './contact.component';
import { environment } from '../environments/environment';
import { RouteGuardService } from './modules/shared/services/route-guard.service';
import { AuthModule } from './modules/auth/auth.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';

export const routes: Routes = [
  {
    path: '', component: AppMainComponent, data: { title: 'Index' }, children: []
  },
  { path: 'about', component: AboutComponent, data: { title: 'About' } },
  { path: 'contact', component: ContactComponent, data: { title: 'Contact' } },
  { path: 'error', component: AppErrorComponent },
  { path: 'accessdenied', component: AppAccessdeniedComponent },
  { path: 'notfound', component: AppNotfoundComponent },
  { path: '**', redirectTo: '/notfound' },
];

if (environment.auth.provider != 'none') {
  //routes[0].children.push({ path: 'auth', loadChildren: () => AuthModule });
}

for (let module of environment.modules) {
  let parts = module.path.split('#');
  routes[0].children.push({
    path: module.key,
    loadChildren: () => import(`src/app/modules/${parts[0]}/${parts[0]}.module`).then(mod => mod[parts[1]]),
    data: module.data,
    canActivate: [RouteGuardService]
  });
}

//routes[0].children.push({ path: '', loadChildren: () => DashboardModule });

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutes {

}// ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
