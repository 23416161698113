import { Component } from '@angular/core';
import { ToolBar } from './modules/shared/services/toolbar.service';

@Component({
    selector: 'my-contact',
    templateUrl: 'contact.component.html'
})

export class ContactComponent {
  constructor(private toolbar: ToolBar) {
    this.toolbar.setTitle('Contacts');
  }
}
