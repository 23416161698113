import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class AppTopBarState {

  searchSource: Array<SearchGroup>;

  private megaMenuActiveSource = new BehaviorSubject(false);
  megaMenuActive = this.megaMenuActiveSource.asObservable();

  private megaMenuClickSource = new BehaviorSubject(false);
  megaMenuClick = this.megaMenuClickSource.asObservable();

  private megaMenuMobileActiveSource = new BehaviorSubject(false);
  megaMenuMobileActive = this.megaMenuMobileActiveSource.asObservable();

  private searchTermSource = new BehaviorSubject<string>(null);
  searchTerm = this.searchTermSource.asObservable();

  private searchResultsSource = new BehaviorSubject<Array<SearchGroup>>(null);
  searchResults = this.searchResultsSource.asObservable();

  private searchValueSource = new BehaviorSubject<SearchItem>(null);
  searchValue = this.searchValueSource.asObservable();

  constructor() { }

  getMegaMenuActive(): boolean {
    return this.megaMenuActiveSource.getValue();
  }

  changeMegaMenuActive(active: boolean) {
    this.megaMenuActiveSource.next(active);
  }

  getMegaMenuClick(): boolean {
    return this.megaMenuClickSource.getValue();
  }

  changeMegaMenuClick(click: boolean) {
    this.megaMenuClickSource.next(click);
  }

  getMegaMenuMobileActive(): boolean {
    return this.megaMenuMobileActiveSource.getValue();
  }

  changeMegaMenuMobileActive(active: boolean) {
    this.megaMenuMobileActiveSource.next(active);
  }

  setSearchTerm(term: string) {
    this.searchTermSource.next(term);
    if (!this.searchSource)
      return;
    var filteredGroups = [];    
    for (let group of this.searchSource) {
      let filteredItems = group.items.filter(y => y.label.toUpperCase().includes(term.toUpperCase()));
      if (filteredItems && filteredItems.length) {
        filteredGroups.push({
          label: group.label,
          //value: optgroup.value,
          items: filteredItems
        });
      }
    }
    this.setSearchResults(filteredGroups);
  }

  setSearchResults(results: Array<SearchGroup>) {
    this.searchResultsSource.next(results);
  }

  setSearchValue(value: SearchItem) {
    this.searchValueSource.next(value);
  }
}

export class SearchItem {
  label: string;
  constructor(label: string) {
    this.label = label;
  }
}

export class SearchGroup {
  label: string;
  icon: string;
  items = new Array<SearchItem>();  

  constructor(label: string, items: Array<SearchItem>) {
    this.label = label;
    this.items = items;
  }
}
