import { Component, OnInit } from "@angular/core";
import { AuthAdapterService } from "../services/auth.service";

@Component({
  selector: 'li[my-user]',
  templateUrl: 'user.component.html',
  styleUrls: ['user.component.less'],
  host: {
    'class': 'topbar-item profile-item',
    '[class.active-topmenuitem]': 'isActive'
  }
})
export class UserComponent implements OnInit {

  isActive = false;

  public get userInfo() {
    return this.service.currentUser;
  }

  public get isAuthenticated() {
    return this.service.isAuthenticated;
  }

  constructor(private service: AuthAdapterService) {
    //service.init();
  }

  ngOnInit(): void {
    this.service.onWindowInit();
  }

  signIn() {
    this.service.login();
  }

  signOut() {
    this.service.logout();
  }  

  onTopbarItemClick(event) {
    this.isActive = !this.isActive;
    event.preventDefault();
  }
}
