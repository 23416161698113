import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { RouteGuardService } from "../services/route-guard.service";

@Injectable({ providedIn: 'root' })
export class RouteGuard implements CanActivate {

    constructor(
        private readonly service: RouteGuardService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
        return this.service.canActivate(route, state);
    }
}
