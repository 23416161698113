import { DuoInterceptor } from "./interceptors/duo.interceptor";

import { AuthAdapterService, AuthInterceptor2 } from "../../services/auth.service";
import { DuoAuthAdapterService } from "./services/duo-adapter.service";

export const providers = [    
    { provide: AuthAdapterService, useClass: DuoAuthAdapterService },
    { provide: AuthInterceptor2, useClass: DuoInterceptor }
];




