import {Component, Inject, forwardRef, EventEmitter, Output, Input} from '@angular/core';
import {AppMainComponent} from './app.main.component';

@Component({
    selector: 'app-rightpanel',
    template: `
        <div class="layout-rightpanel" [ngClass]="styleClass" (click)="onPanelClick($event)">

			<div class="right-panel-header">
				<div class="title">
					<h1>{{header}}</h1>
				</div>
				<a href="#" class="rightpanel-exit-button" (click)="onCloseClick($event)">
					<i class="pi pi-times"></i>
				</a>
			</div>
			<div class="right-panel-content">
				<ng-content></ng-content>
            </div>
        </div>
    `,
	styles: [`
		.layout-rightpanel .right-panel-header .title h1 {
			margin: 0
		}
		.layout-rightpanel .right-panel-content {
			padding: 20px;
			width: 280px;
		}		
		:host ::ng-deep .p-multiselect-item > span {
			white-space: normal;
			overflow: hidden !important;
		}		
	`]
})//<router-outlet name="rightPanel" (activate)="this.onActivate($event)" (deactivate)="app.onRightPanelClose($event)"></router-outlet>
export class AppRightPanelComponent {
  @Input() header: string;
  @Input() styleClass: string;
	@Output() close = new EventEmitter();

	constructor(public app: AppMainComponent) { }

	onCloseClick() {
		this.close.emit();
  }

  onPanelClick(event) {
    //this.app.onRightPanelClick(event);
    event.preventDefault();
  }
}
