import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkItem {

  //#region Fields
  private events: { [id: string]: Subject<any>; } = {};

  //#endregion  

  constructor(@Optional() @SkipSelf() parentWorkItem?: WorkItem) {
    if (parentWorkItem) {
      throw new Error(
        'WorkItem is already created. Import it in the AppModule only.');
    }
  }
  //#region Methods

  private getEvent<T>(name: string): Subject<T> {
    var res = this.events[name];
    if (!res) {
      res = new Subject<T>();
      this.events[name] = res;
    }
    return res;
  }

  public emit<T>(args: T) {
    var event = this.getEvent<T>(args.constructor.name);
    event.next(args);
  }

  public subscribe<T>(name, next: (value: T) => void): Subscription{
    return this.getEvent<T>(name.name)
      .subscribe(next);
  }

  //#endregion
}
