import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Injector, Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {

  private readonly strategies: Array<RouteGuardStrategy>;

  constructor(private injector: Injector, private router: Router) {
    this.strategies = new Array<RouteGuardStrategy>();
  }

  addGuard(strategy: RouteGuardStrategy) {
    this.strategies.push(strategy);
  }

  addGuardFactory<TStrategy extends RouteGuardStrategy>(strategy: { new(...args: any[]): TStrategy }) {
    this.strategies.push(this.injector.get(strategy));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (environment.auth.provider != 'none' && this.strategies.length == 0) {
      this.router.navigate(['/auth/login'], {
        queryParams: {
          redirectUrl: state.url
        }
      });
      return false;
    }
    return this.strategies.every((x) => x.canActivate(route, state));
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(route, state);
  }
}

export interface RouteGuardStrategy {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean;
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean;
}
