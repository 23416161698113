import { NG_VALIDATORS, Validator, AbstractControl,  Validators } from "@angular/forms";
import { Directive, Input } from "@angular/core";


@Directive({
  selector: '[max]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidator, multi: true }]
})
export class MaxValidator implements Validator {
  @Input('max') number: number;

  validate(control: AbstractControl): { [key: string]: any } {
    return Validators.max(this.number)(control);
  }
}

