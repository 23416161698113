import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";

export class DuoInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //if (req.url.endsWith('user'))
            return next.handle(req);
        //else
        //    throw new Error('Not implemented.');
    }

}
