import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { Menu } from "./modules/shared/services/menu.service";

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  model: any[];

  constructor(public app: AppMainComponent, private readonly menu: Menu) {

  }

  ngOnInit() {
    this.menu.get().subscribe((x) => {
      this.model = [];
      x.sort((a, b) => a.name > b.name ? 1 : -1).forEach(y => {
        y.items.sort((a, b) => a.name > b.name ? 1 : -1).forEach(z => {
          this.model.push({
            label: z.name,
            icon: z.icon ? 'pi pi-fw ' + z.icon : undefined,
            routerLink: z.type == 'group' ? z.items.map(i => i.parent + '/' + i.link) : [z.parent, { outlets: { primary: [z.link], 'mmenu': z.menuLink ? [z.menuLink] : null } }],
            items: z.items ? z.items.sort((a, b) => a.name > b.name ? 1 : -1).map(i => {
              var primaryOutlet = i.linkParams ? [i.link].concat(i.linkParams) : [i.link];
              var mmenuOutlet = i.menuLink ? [i.menuLink] : null;
              return {
                label: i.name,
                icon: 'pi pi-fw ' + (i.icon ? i.icon : 'pi-file-o'),
                routerLink: [i.parent, { outlets: { primary: primaryOutlet, 'mmenu': mmenuOutlet } }]
              };
            }) : null
          });
        })
      });
    });
  }

  onMenuClick() {
    this.app.menuClick = true;
  }
}
