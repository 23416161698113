import { Component } from '@angular/core';
import { ToolBar } from './modules/shared/services/toolbar.service';

declare var L: any;

@Component({
  selector: 'my-index',
  templateUrl: 'index.component.html',
  providers: []
})
export class IndexComponent {
  constructor(private toolbar: ToolBar) {
    this.toolbar.setTitle('Welcome');
  }
}

