import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'whereBy' })
export class WherePipe implements PipeTransform {
  transform<T>(collection: Array<T>, predicate: (x: T) => boolean): Array<T> {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return null;
    }

    return collection.filter((x,i,c) => predicate(x));
  }
}
