import { Component, Input } from "@angular/core";

@Component({
  selector: 'vms-spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.less']
})
export class SpinnerComponent {
  @Input() visibile: boolean;
}
