import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
  transform<T>(collection: Array<T>, property: string, ascending: boolean = false): Array<T> {
    // prevents the application from breaking if the array of objects doesn't exist yet
    if (!collection) {
      return null;
    }

    return collection.sort((a, b) => {
      if (a[property] < b[property]) { return !ascending ? 1 : -1; }
      if (a[property] > b[property]) { return !ascending ? -1 : 1; }
      return 0;
      //b[property] - a[property]
    });
  }
}
