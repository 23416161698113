// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    api: 'https://vms.chandris.com:4430/api/',//54752
    odata: 'https://vms.chandris.com:4430/odata/',//54752
    branding: {
        color: '#618d9e',
        logo: 'vms',
        name: 'Chandris',
        title: 'VMS Web - {title}',
        text: '#ffffff'
    },
    auth: {
        //provider: 'none'
        provider: 'azure-ad',
        settings: {
            authority: 'https://login.microsoftonline.com/e9217b3d-de85-407c-963d-107f1a9f4216', // Prod environment. Uncomment to use.
            clientId: '058b7d74-08ad-4778-acb7-223a695d1d0c',
            redirectUri: '/',
            postLogoutRedirectUri: '/',
            navigateToLoginRequestUrl: false,
            endpoints: {
                "https://vms.chandris.com:4430/api": '4e2d643d-c068-4131-ba71-60a1d0832ccb',
                "https://vms.chandris.com:4430/odata": '4e2d643d-c068-4131-ba71-60a1d0832ccb'
            }
        }
    },
    modules: [
        {
            key: '',
            path: 'dashboard#DashboardModule',
            data:
            {
                customTooltip: true
            }
        },
        {
            key: 'common',
            path: 'common#VMSCommonModule'
        },
        {
            key: 'ais-movement',
            path: 'ais-movement#AisMovementModule'
        },
        {
            key: 'chartering',
            path: 'chartering#CharteringModule',
            security: {
                'charteringlist': ['*']
            }
        },
        {
            key: 'distanceviewer',
            path: 'distanceviewer#DistanceViewerModule',
            security: {
                'distanceviewer': ['*']
            },
        },
        {
            key: 'earnings',
            path: 'earnings#EarningsModule'
        },
        {
            key: 'notification',
            path: 'notification#NotificationModule'
        },
        {
            key: 'portcall-management',
            path: 'portcall-management#PortCallManagementModule'
        },
        {
            key: 'cargo',
            path: 'cargo#CargoModule',
            //canActivate: [AdalGuard]
        },
        {
            key: 'baltic',
            path: 'baltic#BalticModule',
        },
        {
            key: 'bunker-price',
            path: 'bunker-price#BunkerPriceModule',
        },
        {
            key: 'reports',
            path: 'fixture-report#FixtureReportModule'
        },
        {
            key: 'reports',
            path: 'report#ReportModule'
        },
        {
            key: 'vessel',
            path: 'vessel#VesselModule',
            data:
            {
                customTooltip: true
            }
        }
    ],
    production: true,
    readonly: true
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
