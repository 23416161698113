import { HttpHandler, HttpEvent, HttpRequest, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

export class AuthInterceptorService {

  private interceptor: HttpInterceptor;
    //constructor(private interceptor: HttpInterceptor) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.interceptor == null)
            return next.handle(request);

        return this.interceptor.intercept(request, next);
    }

  public setInterceptor(interceptor: HttpInterceptor): void {
        this.interceptor = interceptor;
    }
}
