import { NgModule, enableProdMode /*NgModuleFactoryLoader,*/ /*, SystemJsNgModuleLoader*/ } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, } from '@angular/common/http';

import { APP_BASE_HREF } from '@angular/common';

// Custom modules
import { SharedModule } from './modules/shared/shared.module';
// Custom interceptors
import { AuthInterceptor } from './modules/shell/interceptors/auth.interceptor';
import { HttpErrorInterceptor } from './modules/shared/interceptors/http-error.interceptor';

// Custom services
import { Logger } from './modules/shared/services/logger.service';
import { Menu } from './modules/shared/services/menu.service';
import { ToolBar } from './modules/shared/services/toolbar.service';

// Configuration
import { environment } from '../environments/environment';

// Custom services
import { Cookie } from './services/cookie.service';
import { ServiceWorkerModule } from '@angular/service-worker';

// Custom components
import { AppComponent } from './app.component';
import { AboutComponent } from './about.component';
import { ContactComponent } from './contact.component';
import { IndexComponent } from './index.component';
import { AuthInterceptorService } from './modules/shared/services/auth-interceptor.service';
import { AppRoutes } from './app.routes';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppRightPanelComponent } from './app.rightpanel.component';
import { AppFooterComponent } from './app.footer.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMainComponent } from './app.main.component';
import { AppTopBarState } from './app.topbar.state';
import { BreadcrumbService } from './breadcrumb.service';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { WorkItem } from './modules/shared/work-item';
import { AuthModule } from './modules/auth/auth.module';

// enableProdMode();

var module = {
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    SharedModule,
    AuthModule,
    AppRoutes
  ],
  declarations: [
    AboutComponent,
      AppComponent,
      AppMainComponent,
      AppMenuComponent,
      AppMenuitemComponent,
      AppTopBarComponent,
      AppFooterComponent,
      //AppRightPanelComponent,
      AppBreadcrumbComponent,
      AppNotfoundComponent,
      AppErrorComponent,
      AppAccessdeniedComponent,
    ContactComponent,
    IndexComponent,
  ],
  children: [],
  providers: [
    BreadcrumbService,
    Cookie,
    Logger,
    Menu,
    ToolBar,
    Title,
    AuthInterceptorService,
    AppTopBarState,
    WorkItem,

    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

    //{ provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
    { provide: APP_BASE_HREF, useValue: '/' }
  ],
  bootstrap: [ AppComponent, MsalRedirectComponent ]
};

@NgModule(module)
export class AppModule {
  constructor(private toolbar: ToolBar) {
    toolbar.setTitle(environment.branding.name);
  }
}


