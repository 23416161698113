import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { SearchItem } from "../../../app.topbar.state";

@Injectable()
export class ToolBar {

  private title: string = '';
  private search: any = null;
  private items: Array<ToolBarItem> = [];

  constructor(
    private readonly titleService: Title) {
  }

  addSwitch(name: string, value: boolean, handler: (x: boolean) => void, parent: string = null) {
    var item = new ToolBarItem('switch', name, value, handler, {});
    if (parent) {
      let parentItem = this.items.find((x) => x.name == parent);
      if (!parentItem)
        throw new Error('Could not find item with name ' + parent);
      if (parentItem.type != 'group')
        throw new Error('Parent item must be a GROUP type');
      parentItem.addChild(item);
    }
    else {
      this.items.push(item);
      this.items = this.items.map((x) => x);
    }
  }

  addRadio(name: string,value:boolean, handler: (x: string) => void, parent: string) {
    var item = new ToolBarItem('radio', name, value, handler, {});

    let parentItem = this.items.find((x) => x.name == parent);
    if (!parentItem)
      throw new Error('Could not find item with name ' + parent);
    if (parentItem.type != 'group')
      throw new Error('Parent item must be a GROUP type');
    parentItem.addChild(item);

  }

  addModalButton(name: string, icon: string, id: string) {
    this.items.push(new ToolBarItem('modalButton', name, name, null, { id: id, icon: icon }));
    this.items = this.items.map((x) => x);
  }

  addButton(name: string, icon: string, handler: () => void) {
    this.items.push(new ToolBarItem('button', name, name, handler, { icon: icon }));
    this.items = this.items.map((x) => x);
  }

  addGroup(name: string, icon: string) {
    this.items.push(new ToolBarItem('group', name, name, null, { icon: icon }));
    this.items = this.items.map((x) => x);

  }
  addSeparator() {
    this.items.push(new ToolBarItem('separator', '', '', null, {}));
    this.items = this.items.map((x) => x);
  }

  addSearchBar(component: ISearchBarSupport) {
    this.search = component;
  }

  setTitle(value: string) {
    this.items.length = 0;
    this.items = this.items.map((x) => x);
    this.search = null;
    this.title = value;
    this.titleService.setTitle(
      environment.branding.title.replace('{title}', value));
  }
}

export interface ISearchBarSupport {
}

class ToolBarItem {
  private children: Array<ToolBarItem> = new Array<ToolBarItem>();

  constructor(
    public type: string,
    public name: string,
    public value: any,
    public handler: (x: any) => void,
    public ptrs: any) { }

  public addChild(child: ToolBarItem) {
    this.children.push(child);
  }
}
