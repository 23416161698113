import {Component, Inject, forwardRef} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
			<div>&copy; 2019 <a src="https://boelitapps.dk">Boel IT Aps</a></div>
            <div>Powered by <a src="https://boelitapps.dk/#VMS_Future">VMS Web</a></div>
        </div>
    `
})
export class AppFooterComponent {

}
